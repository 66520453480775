import { animations } from "./animations";
// import { movies } from "./movies";
// import { documentaries } from "./documentaries";
// import { events } from "./events";

export const en = {
  ...animations,
  //   movies,
  //   documentaries,
  //   events,
  // Basic translations
  logo: "TURBO PLAYHOUSE",
  w2e: "W2E (Watch to Earn) Service Platform",
  claimReward:
    "Watch various videos and receive TurboChain (TBC) reward tokens!",
  connectWallet: "Connect Wallet",
  tbc: "TBC",
  language: "Language",
  animation: "TV Animation",
  movie: "Movie",
  game: "Game",
  documentary: "Documentary",
  event: "Event",
  views: "Total Views",
  accumulatedRewards: "Total Rewards",
  highQualityContent: "Diverse High-Quality Content",
  tradeOnDIGIFINEX: "Trade on DIGIFINEX",
  tradeOnXT: "Trade on XT",
  tradeRewards: "Easily trade and withdraw rewards anytime on the exchange",
  community: "Community",
  telegram: "Telegram",
  aboutUs: "About Us",
  projectHomepage: "TurboChain",
  foundationHomepage: "Asia Star",
  whitepaper: "Whitepaper",
  footerText: "Asia Star Entertainment Co., Ltd.",
  footerText1:
    " {'|'} CEO: Young-sik Eom {'|'} Business Registration Number: 214-88-76261",
  footerText2:
    "Address: 1308, 111 Digital-ro 26-gil, Guro-gu, Seoul | TEL: 070-4285-1680",
  footerText3: "Asia Star Entertainment All rights reserved",
  watchNow: "Watch Now",
  moreDetails: "More Details",
  episodeList: "Episodes",
  contentInfo: "Details",
  description: "Description",
  detailedDescription: "Detailed Description",
  genre: "Genre",
  director: "Director",
  writer: "Writer",
  cast: "Cast",
  network: "Network",
  rating: "Rating",
  reward: "Reward",
  claimButtonModal:
    "You will be automatically claimed upon completion of watching the video.",
  minutes: "Minutes",
  aired: "Aired",
  title: "Title",
  summary: "Summary",
  connectWalletMessage: "Please connect your wallet first!",
  rewardClaimed: "Reward Claimed",
  more: "More",
  less: "Less",
};
