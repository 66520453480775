export const greatQbot = {
  title: "그레이트 큐봇",
  description:
    "깨끗한 지구를 지키기 위해 싸우는 혜성과 렉스 그리고 그의 친구들이 펼치는 이야기",
  detailedDescription:
    "무한한 우주, 지구로부터 500광년 떨어진 행성 ‘케플러'는 자연으로부터 나오는 에코에너지를 무분별하게 사용해 황폐화 된다. 새로운 보금자리를 만들 행성이 필요한 케플러인들은 아름답고 깨끗한 행성, ‘지구’를 발견하고 이주 계획을 세운다.",
  genre: "Full 3D TV 시리즈 애니메이션",
  director: "엄영식",
  writer: "엄영식",
  cast: "안소이(성우), 홍진욱(성우)",
  summary: "(주)아시아스타엔터인먼트, (주)리얼픽셀 / 2024.05.25 ",
  episodes: [
    {
      name: "1화: 렉스의 등장",
      description:
        "가족과 함께 캠핑을 간 혜성의 눈앞에 나타난 시추선! 지구인에게 에코에너지 추출 작업을 들킨 지그재그는 혜성의 뒤를 쫓기 시작하고, 혜성은 부모님인 유철, 소희와 함께 자동차를 타고 달아난다. 깊은 숲에서 서로 쫓고 쫓기는 가운데 위험을 감지한 자동차가 큐봇 킹-렉스로 변신하는데…",
    },
    {
      name: "2화: 계곡에서의 낯선만남",
      description:
        "혜성은 단짝친구인 은하와의 약속을 지키기 위해 자동차 렉스를 타고 생태공원으로 달려간다. 생태공원은 원인 모를 산사태가 진행 중! 혜성이와 렉스는 낙석으로 인하여 위험에 처한 은하를 구해내고, 은하의 관찰 사진에 우연히 찍힌 시추선이 원인 모를 산사태와 관련이 있다 생각하는데…",
    },
  ],
};
