<template>
  <div class="reward">
    <p>{{ $t("reward") }}:&nbsp;</p>
    <button
      v-if="isRewardClaimed(episode.video_id)"
      class="btn btn-primary"
      disabled
    >
      {{ $t("rewardClaimed") }}
    </button>
    <button
      class="btn btn-primary amount"
      @click="modalVisible = !modalVisible"
      v-else
    >
      {{ episode.reward }} TBC
    </button>
    <b-modal
      id="modal"
      title=""
      ok-only
      v-model="modalVisible"
      ok-title="Close"
      @ok="modalVisible = false"
    >
      <p>{{ $t("claimButtonModal") }}</p>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BModal } from "bootstrap-vue-next";

export default {
  name: "RewardB",
  components: {
    BModal,
  },
  props: {
    episode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: false,
    };
  },
  computed: {
    ...mapState(["rewardVideoIds"]),
  },
  methods: {
    isRewardClaimed(videoId) {
      return this.rewardVideoIds.includes(videoId);
    },
  },
};
</script>

<style lang="scss" scoped>
.reward {
  display: flex;
  justify-content: normal !important;
  margin-bottom: 0.5em;
  p {
    font-size: 24px;
    font-weight: 500;
  }
  button {
    font-weight: 600;
  }
}

@media (max-width: 991px) {
  .reward {
    margin-bottom: 0;
    align-items: flex-end;
    display: flex !important;
    p {
      font-size: 12px;
    }
    button {
      font-size: 10px;
      padding: 0.1em 0.5em;
      border-radius: 0.3em;
    }
  }
}
</style>
