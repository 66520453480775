export const greatQbot = {
  title: "Great Qbot",
  description:
    "The story of Comet, Rex, and their friends fighting to protect a clean Earth",
  detailedDescription:
    "In the vast universe, the planet 'Kepler', located 500 light years from Earth, becomes desolate from the indiscriminate use of Echo Energy derived from nature. The Keplerians, in need of a new habitat, discover the beautiful and clean planet 'Earth' and set up a plan to migrate.",
  genre: "Full 3D TV Series Animation",
  director: "Young-sik Eom",
  writer: "Young-sik Eom",
  cast: "So-yi Ahn (voice actor), Jin-wook Hong (voice actor)",
  summary: "Asia Star Entertainment, Real Pixel / 2024.05.25",
  episodes: [
    {
      name: "Episode 1: The Appearance of Rex",
      description:
        "In front of Comet, who went camping with his family, a drilling rig appears! Zigzag, caught by humans extracting Echo Energy, starts following Comet, who flees in a car with his parents Yu-cheol and So-hee. In the midst of being chased through the deep forest, sensing danger, the car transforms into Qbot King-Rex...",
    },
    {
      name: "Episode 2: Stranger Meetings in the Valley",
      description:
        "Comet runs to the ecological park in his car Rex to keep his promise to his best friend Eunha. An unknown landslide is underway at the ecological park! Comet and Rex rescue the galaxy in danger from falling rocks, and think that a drilling rig accidentally taken in an observation photo of the galaxy has something to do with an unknown landslide...",
    },
  ],
};
